import loanProductservice from '@/services/loan-product-service'
import moment from 'moment'

export default  {
  state : {
    loanProducts: [],
    totalCount: 0,
    policyTypes: ['Staff', 'Regular', 'Staff Age', 'Regular Age'],
    loanProduct: {
      ID: '',
      loan_product_name        : '',
      loan_product_code        : '',
      loan_product_type        : '',
      loan_pool                : '',
      loan_product_checklist   : [],
      loan_floor               : '',
      loan_ceiling             : '',
      loan_type_id             : '',
      start_date               : '',
      end_date                 : '',
      minimum_age              : 0,
      is_active                : true,
      use_adjustments          : false,
      use_risk_factor_score    : false,
      monthly_payment_calculation : 'reducing',
      loan_product_sub_type       : 'none',
      loan_policy_terms_to_use : 'current',
      apply_service_charge_to  : 'new',
      attract_gct              : true,
      policies                 : [],
      service_charges          : [],
      member_age_policies      : [],
      staff_age_policies       : [],
      use_dsr                  : false,
      use_networth              : false,
    },
    policy: {
      type                  : '',
      interest              : 0,
      tenure                : 0,
      shares                : 0,
      dsr                   : 0,
      value_collateral_used : 0
    },
    agePolicy: {
      age                   : 0,
      loan_ceiling          : 0.00,
      loan_floor            : 0.00,
      interest              : 0.00,
      tenure                : 0.00,
      shares                : 0.00,
      dsr                   : 0.00,
      value_collateral_used : 0.00
    },
    message: {},
    isLoading: false
  },

  getters : {
  
  },

  mutations : {
    SET_LOAN_PRODUCTS(state, {data, total}) {
      state.loanProducts = data
      state.totalCount = total
    },
    ADD_LOAN_PRODUCT(state, obj) {
      state.loanProducts.push(obj)
    },
    UPDATE_LOAN_PRODUCT(state, {index, obj}) {
      // obj.policies.forEach((el,i, arr)=>{
      //   let o = _.clone(el)
      //   o.interest = Number(o.interest) * 100 
      //   o.shares = Number(o.shares) * 100
      //   o.dsr    = Number(o.dsr) * 100,
      //   o.value_collateral_used = Number(o.value_collateral_used) * 100
      //   arr[i] = o
      // })
      console.log('updated', {obj})
      state.loanProducts[index] = obj
    },
    DELETE_LOAN_PRODUCT(state,  index) {
      state.loanProducts.splice(index, 1)
    },
    SET_ERROR_MESSAGE(state, {type = 'error', title = '', content = ''}) {
      state.message = {...type, ...title, ...content}
    }
  },

  actions : {
    async getLoanProducts ({state, commit}, obj) {
      try{
        state.isLoading = true
        let response = await loanProductservice.findAll(obj.filter, obj.sort, obj.paginate)
        if(response) {
          commit('SET_LOAN_PRODUCTS', {data: response.data, total: response.total})
        }
      }catch(e){
       throw e
      } finally {
        state.isLoading = false
      }
    },

    async createLoanProduct({state, commit}, obj) {
      try {
        console.log('enter creat', obj)
        state.isLoading = true
        let createObj = {...obj}
        delete createObj['loan_type_id']
        delete createObj['ID']
        delete createObj['loan_type_name']
        delete createObj['loan_type_fees']
        delete createObj['loan_type_checklist']
        delete createObj['items']
        delete createObj['date_updated']
        delete createObj['date_updated']

       
        let selectedItems = obj.loan_product_checklist.filter(el=> el.is_selected )
        createObj.loan_type_checklist = selectedItems

        let response = await loanProductservice.create(obj.loan_type_id, createObj)
        if(response) {
          obj.ID = response.data
          obj.date_created = moment().utc().format('YYYY-MM-DD HH:mm:ss')
          commit('ADD_LOAN_PRODUCT', obj)
        }
      } catch(e) {
        throw e
      } finally {
        state.isLoading = false
      }
    },

    async updateLoanProduct({state, commit}, {index, obj}) {
      try {
        state.isLoading = true
        let updatedObj = Object.assign({}, obj)
        delete updatedObj['ID']
        delete updatedObj['loan_type_id']
        delete updatedObj['loan_type_name']
        delete updatedObj['loan_type_fees']
        delete updatedObj['loan_type_category']
        delete updatedObj['items']
        delete updatedObj['date_created']
        delete updatedObj['loan_type_checklist']
        delete updatedObj['date_updated']
        
        let selectedItems = obj.loan_product_checklist.filter(el=> el.is_selected ).map((el)=> el.ID)
        updatedObj.loan_product_checklist = selectedItems
        
        await loanProductservice.update(obj.loan_type_id, obj.ID, updatedObj)
        commit('UPDATE_LOAN_PRODUCT', {index, obj})
        
      } catch(e) {
        throw e
      } finally {
        state.isLoading = false
      }
    },

    async deleteLoanProduct({state, commit}, index) {
      try {
        state.isLoading = true
        let id = state.loanProducts[index].ID
        let response = await loanProductservice.delete(id)
        if(response) {
          commit('DELETE_LOAN_PRODUCT', id)
        }
      } catch(e) {
        throw e
      } finally {
        state.isLoading = false
      }
    },
  }
}