
export default {

    path: 'settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "login" */ '@/layouts/Settings-Layout.vue'),
    redirect: {
      name: "company"
    },
    children: [
      {
        path:'company',
        name:'company',
        component: () => import(/* webpackChunkName: "login" */ '@/views/settings/Company-Settings.vue'),
      },
      {
        path:'branches',
        name:'branches',
        component: () => import(/* webpackChunkName: "login" */ '@/views/settings/Branch-Settings.vue'),
      },
      {
        path:'departments',
        name:'departments',
        component: () => import(/* webpackChunkName: "login" */ '@/views/settings/DepartmentSettings.vue'),
      },
      {
        path:'roles',
        name:'roles',
        component: () => import(/* webpackChunkName: "login" */ '@/views/settings/Role-Settings.vue'),
      },
      {
        path:'users',
        name:'users',
        component: () => import(/* webpackChunkName: "login" */ '@/views/settings/Users-Settings.vue'),
      },
      {
        path:'factors',
        name:'factors',
        component: () => import(/* webpackChunkName: "login" */ '@/views/settings/RiskFactor-Settings.vue'),
      },
      {
        path:'group-factors',
        name:'group-factors',
        component: () => import(/* webpackChunkName: "login" */ '@/views/settings/Group-RiskFactor-Settings.vue'),
      },
      {
        path:'adjustments',
        name:'adjustments',
        component: () => import(/* webpackChunkName: "login" */ '@/views/settings/Adjustments-Settings.vue'),
      },
      {
        path:'id-types',
        name:'id-types',
        component: () => import(/* webpackChunkName: "login" */ '@/views/settings/ID-Type-Settings.vue'),
      },
      {
        path:'mass-upload',
        name:'mass-upload',
        component: () => import(/* webpackChunkName: "login" */ '@/views/settings/Mass-Upload.vue'),
      },
      {
        path: 'loan-templates',
        name: 'loan-templates',
        component: () => import(/* webpackChunkName: "login" */ '@/views/settings/loan-templates/LoanApplicationTemplates.vue'),
      },
      {
        path: 'account-general-settings',
        name: 'account-general-settings',
        component: () => import(/* webpackChunkName: "login" */ '@/views/settings/account-management-settings/Account-General-Settings.vue')
      },
      {
        path: 'account-types-settings',
        name: 'account-types-settings',
        component: () => import(/* webpackChunkName: "login" */ '@/views/settings/account-management-settings/Account-Types-Settings-Layout.vue'),
        redirect: {
          name: "account-types"
        },
        children: [
          {
            path: 'account-types',
            name: 'account-types',
            component: () => import(/* webpackChunkName: "login" */ '@/views/settings/account-management-settings/Account-Type-Settings.vue')
          },
          {
            path: 'sub-accounts',
            name: 'sub-accounts',
            component: () => import(/* webpackChunkName: "login" */ '@/views/settings/account-management-settings/Sub-Account-Settings.vue')
          },
          {
            path: 'account-definitions',
            name: 'account-definitions',
            component: () => import(/* webpackChunkName: "login" */ '@/views/settings/account-management-settings/Account-Definition-Settings.vue')
          },
        ]
    }
    ]
}