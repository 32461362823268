import AuthenticateService from '@/services/authentication-service'
import companyService from '@/services/company-service'
import storageService from "@/services/storage-service";
import moment from "moment";

export default  {
  state : {
    isAuthenticated: false,
    authUser: {},
    errorMessage: "",
    errorType: "error",
    isLoading: false,
    company: {},
  },

  getters : {
    getAuthUserAvatar(state) {
      let user = storageService.getItem('user')
      return state.authUser.avatar || user.avatar || require("@/assets/img/avatar.png")
    },
    getAuthUser(state) {
      let user = storageService.getItem('user')
      return Object.keys(state.authUser).length !== 0 ? state.authUser : user
    },

  },

  mutations : {
    LOGIN_USER (state, obj){
      state.errorMessage = ""
      state.authUser = obj
    },
    SET_ERROR_MESSAGE(state, message) {
      state.errorMessage = message
    },
    SET_COMPANY(state, company) {
      state.company = company
    }
  },

  actions : {

    
    fetchCompanyDetails: async({commit, state}) =>{
      state.isLoading = true
      state.errorMessage = ""
      try{
        let result = await companyService.find()
        console.log(result)
        commit('SET_COMPANY', result.data)
      }catch(e){       
        state.errorMessage = "Something went wrong while authenticating... Please check your internet connection..."
        throw e
      }finally{
        state.isLoading = false;
      }
    },

    updateCompanyDetails: async({commit, state}, company) =>{
      state.isLoading = true
      state.errorMessage = ""
      try{
        await companyService.update(company)
        commit('SET_COMPANY', company)
      }catch(e){       
        state.errorMessage = "Something went wrong while authenticating... Please check your internet connection..."
        throw e
      }finally{
        state.isLoading = false;
      }
    },

    authenticate:  async ({commit, state}, obj) => {
      state.isAuthenticated = false
      state.isLoading = true
      state.errorMessage = ""

      try{
        let response = await AuthenticateService.authenticate(obj.email, obj.password)
        if(response){
          state.errorMessage = ""
          const token = response.data.token;
          const refreshToken = response.data.refresh_token;

          delete response.data['token'];
          delete response.data['refresh_token'];

          const userResponse = response.data;
          const res = await AuthenticateService.getUserPrivileges(userResponse.ID)
          const privilegesAndAccess = res.data || []

          const userData = {
            ...userResponse, 
            ...privilegesAndAccess
          }
          
          storageService.setItem('branch', JSON.stringify(response.data.branch))
          storageService.setItem('token', token)
          storageService.setItem('refresh_token', refreshToken)
          storageService.setItem('user', JSON.stringify(userData))
          storageService.setItem('time_logged_in', moment().format('YYYY-MM-DD hh:mm:ss A'))          
  
          let user = Object.assign({}, response.data)

          commit('LOGIN_USER', user)
          state.isAuthenticated = true
        }else{
          state.errorMessage = "Incorrect credentials. Please check your username and password then try again."
        }
      }catch(e){      
        console.log(e) 
        state.errorMessage = "Something went wrong while authenticating... Please check your internet connection..."
        switch(e.response.status) {
          case 404:
            state.errorMessage = "Incorrect credentials. Please check your username and password then try again."
          break
          case 422:
            state.errorMessage = "Invalid request data. Please review request and try again."
          break
          case 401:
            state.errorMessage = `Incorrect credentials. Please check your username and password then try again.`
          break
          case 403:
            state.errorMessage = "Your Account was disabled due to secuirty reasons. Contact your administrator for support."
          break
           
        }
      }finally{
        state.isLoading = false;
      }
      
    }
  }
}